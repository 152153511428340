*{
	margin: 0;
	padding: 0;
}
@font-face {
    font-family: "Anteb Regular";
    src: url('./fonts/Anteb-Regular.eot');
    src: url("./fonts/Anteb-Regular.eot?#iefix") format("embedded-opentype"),
         url("./fonts/Anteb-Regular.woff2") format("woff2"), 
         url("./fonts/Anteb-Regular.woff") format("woff"),
         url("./fonts/Anteb-Regular.ttf") format("truetype");
}
html, body, h1, h2, h3, h4, ul, ol, li, p, small, label, input,pre {
    font-family: "Anteb Regular","PingFang SC","Hiragino Sans GB","Heiti SC","WenQuanYi Micro Hei",sans-serif !important;
    font-size: 40%;
}

.ai-btn{
	font-size: 0.3rem;
	background: #669a84;
	color: #fff;
	text-decoration: none;
	border-radius: 20px;
	padding: 10px 30px;
}
.ai-header-wrapper{
	background: #f0f4f6;
	border-bottom: 1px solid #e3e9ec;
	padding: 10px;
	margin-bottom: 30px;
}
.ai-header{
	max-width: 960px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
}
.ai-header h1{
	background: url(./assets/logo.png) no-repeat 0 0;
	background-size: auto 90%;
	padding-left: 1rem;
	font-size: 0.5rem;
	line-height: 2;
}
.ai-header .ai-btn{
	float: right;
}
.ai-body{
	text-align: center;
	line-height: 1.8;
	padding-bottom: 100px;
}
.ai-body img{
	max-width: 99%;
}
.ai-content{
	font-size: 1rem;
	max-width: 600px;
	margin: 10px auto;
	padding: 0 10px;
}