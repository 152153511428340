.app-charge-main{
	border: 1px solid #ccc;
	background: #fff;
	margin: 5px;
	padding: 10px 10px 50px;
	border-radius: 5px;
	font-family: verdana;
	font-size: 0.3rem;
}
.app-charge-main *{
	margin:0;
	padding: 0;
}

.app-charge-title{
	font-size: .5rem;
	line-height: 1rem;
	padding: 0.2rem 0;
}

.app-charge-to,
.app-charge-from{
	white-space: nowrap;
	margin: .3rem 0 0;
}
.app-charge-btns{
	margin: 20px 0;
	text-align: center;

	button{
		background: #00BAFA;
		color: #fff;
		border: none;
		width: 90%;
		border-radius: 10px;
		font-size: 16px;
		padding: 15px 0;
	}
}

.app-charge-amount{
	margin: 1rem 0 0;
	position: relative;
	input {
		padding: 10px;
		width: 94%;
	}
	.max{
		color: #00BAFA;
		position: absolute;
		white-space: nowrap;
		right: 10px;
		top: 10px;
	}
}

.app-charge-balance{
	text-align: center;
}
.app-charge-deposit{
	text-align: center;
	margin-top: 1rem;
}